
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import React from "react";
import Layout from "components/Layout";
import { getGlobals } from "lib/getGlobals";
import Meta from "components/Meta";
import Header from "components/Header";
import Link from "next/link";
import { EmojiSadIcon } from "@heroicons/react/outline";

export default function NotFoundPage({ globals }) {
  return (
    <Layout
      globals={globals}
      header={<Header crumbs={[]} title="Erreur 404" />}
    >
      <Meta
        title="Erreur 404"
        description="La page que vous recherchez n'a pas été trouvée."
      />

      <section className="py-16">
        <div className="relative container mx-auto px-4 lg:max-w-screen-lg">
          <div className="prose prose-white text-center">
            <EmojiSadIcon className="h-8 w-8 inline-block" />
            <p>La page que vous recherchez n&apos;a pas été trouvée.</p>
            <Link href="/" passHref>
              <a className="button button--primary">Retour à l&apos;accueil</a>
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  );
}

 async function _getStaticProps(context) {
  return {
    props: {
      globals: await getGlobals(context),
    },
    revalidate: 30,
  };
}


    export async function getStaticProps(ctx) {
        let res = _getStaticProps(ctx)
        if(typeof res.then === 'function') res = await res
        return {
          
          ...res,
          props: {
            ...(res.props || {}),
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/404',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  