import React, { useRef } from "react";
import Image from "next/image";
import Breadcrumb from "components/Breadcrumb";
import useIsInViewport from "hook/useIsInViewport";
import { Transition } from "@headlessui/react";

export default function Header({
  crumbs,
  title,
  image = null,
  imageFallback = "/images/header.jpg",
}) {
  const ref = useRef(null);
  const isInViewport = useIsInViewport(ref);

  return (
    <section ref={ref} className="relative py-80 pb-32 overflow-hidden">
      <div className="absolute inset-0">
        {image?.image_style_uri ? (
          <Image
            src={image.image_style_uri.hq}
            {...image.resourceIdObjMeta}
            layout="fill"
            objectFit="cover"
            objectPosition="center"
          />
        ) : (
          <Image
            src={imageFallback}
            width="1800"
            height="660"
            layout="fill"
            objectFit="cover"
            objectPosition="center"
            alt="Bannière d'en-tête"
          />
        )}

        <div
          className="block md:hidden absolute inset-0 bg-secondary/30 z-10"
          aria-hidden="true"
        />
        <div
          className="hidden md:block absolute inset-0 bg-gradient-to-t from-secondary via-transparent z-10"
          aria-hidden="true"
        />
        <div
          className="hidden md:block absolute inset-0 bg-gradient-to-r from-secondary z-10"
          aria-hidden="true"
        />

        <div
          className="absolute inset-0 bg-pattern bg-repeat opacity-10 bg-[length:4px]"
          aria-hidden="true"
        />
      </div>

      <div
        className="absolute top-3/4 left-0 p-8 bg-gradient-to-r from-white  via-transparent bg-clip-text text-transparent text-[210px] font-serif whitespace-nowrap overflow-hidden -translate-y-1/2 opacity-10 z-10"
        aria-hidden="true"
      >
        {title}
      </div>

      <Transition
        show={isInViewport}
        className="relative container mx-auto px-4 text-white z-20"
      >
        <Transition.Child
          enter="transition-all duration-500 delay-300"
          enterFrom="opacity-0 -translate-y-5"
          enterTo="opacity-100 translate-y-0"
          leave="transition-all duration-300"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 -translate-y-5"
        >
          <Breadcrumb crumbs={crumbs} className="mb-4" />
        </Transition.Child>
        <Transition.Child
          as="h1"
          enter="transition-all duration-500 delay-500"
          enterFrom="opacity-0 -translate-x-10"
          enterTo="opacity-100 translate-x-0"
          leave="transition-all duration-300"
          leaveFrom="opacity-100 translate-x-0"
          leaveTo="opacity-0 -translate-x-10"
          className="flex-1 text-4xl lg:text-7xl font-serif"
        >
          {title}
        </Transition.Child>
      </Transition>
    </section>
  );
}
