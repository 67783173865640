import React from "react";
import Link from "next/link";
import useTranslation from "next-translate/useTranslation";

export default function Breadcrumb({ crumbs, className, isInViewport = true }) {
  const { t } = useTranslation("common");
  const transitionDuration = 0.5;
  const transitionDelay = transitionDuration * 0.75;

  return (
    crumbs && (
      <nav aria-label={t("breadcrumb.title")} className={className}>
        <ol className="flex flex-wrap items-center">
          {crumbs.map((crumb, index) => {
            const isFirst = index === 0;
            const isLast = index === crumbs.length - 1;
            const thisTransitionDelay = transitionDelay * index;

            return (
              <li
                key={`crumb-${crumb.title}`}
                className="font-medium transition-all"
                style={{
                  opacity: isInViewport ? 100 : 0,
                  transform: `translateX(${isInViewport ? 0 : "-1rem"})`,
                  transitionDuration: `${transitionDuration}s`,
                  transitionDelay: `${thisTransitionDelay}s`,
                }}
              >
                {!isFirst && (
                  <span
                    className="inline-block px-2 separator"
                    aria-hidden="true"
                  >
                    /
                  </span>
                )}
                {!isLast && crumb.path ? (
                  <Link
                    href={crumb.translate ? t(crumb.path) : crumb.path}
                    passHref
                  >
                    <a className="text-primary underline underline-offset-4 decoration-transparent hover:decoration-primary transition-colors">
                      {crumb.translate ? t(crumb.title) : crumb.title}
                    </a>
                  </Link>
                ) : (
                  <span className="text-opacity-80">
                    {crumb.translate ? t(crumb.title) : crumb.title}
                  </span>
                )}
              </li>
            );
          })}
        </ol>
      </nav>
    )
  );
}
